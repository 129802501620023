//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-884:_3556,_9000,_3872,_7080,_5420,_2904,_7976,_7540;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-884')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-884', "_3556,_9000,_3872,_7080,_5420,_2904,_7976,_7540");
        }
      }catch (ex) {
        console.error(ex);
      }